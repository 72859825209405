







































import { Component, Vue } from 'vue-property-decorator';
import { IServiceCreate } from '@/interfaces';
import { dispatchCreateService, dispatchGetServices } from '@/store/admin/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class CreateService extends Vue {
  public valid = false;
  public name: string = '';
  public code: string = '';
  public externalName: string | null = null;
  public imagesAllowed: boolean = false;
  public isEmail: boolean = false;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetServices(this.$store);
    this.reset();
  }

  public reset() {
    this.name = '';
    this.code = '';
    this.externalName = null;
    this.imagesAllowed = false;
    this.isEmail = false;
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const updatedService: IServiceCreate = {
        name: this.name,
        code: this.code,
        images_allowed: this.imagesAllowed,
        external_name: this.externalName,
        is_email: this.isEmail,
      };
      const result = await dispatchCreateService(this.$store, updatedService);
      if (result) {
        this.$router.push('/main/admin/services');
      }
    }
  }
}
